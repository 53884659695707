/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import scountApi from '@/services/scount-api';

const state = {
  responseStatus: null,
  trainingPlan: null,
};

const mutations = {
  setResponseStatus(state, value) {
    state.responseStatus = value;
  },
  setTrainingPlan(state, value) {
    state.trainingPlan = value;
  },
};

const getters = {
  responseStatus: (state) => state.responseStatus,
  trainingPlan: (state) => state.trainingPlan,
};

const actions = {
  physicalLevel({ commit }, { participantId, physicalLevel }) {
    return scountApi.physicalLevel({ participantId, physicalLevel })
      .then((response) => {
        commit('setResponseStatus', response.status);
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  sendTrennings({ commit }, { trainingPlan }) {
    return scountApi.sendTrennings({ trainingPlan })
      .then((response) => {
        commit('setResponseStatus', response.status);
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  sendTrenningsPurchase({ commit }, { tariffName }) {
    return scountApi.sendTrenningsPurchase({ tariffName })
      .then((response) => {
        commit('setResponseStatus', response.status);
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
  getTrainingPlan({ commit }) {
    return scountApi.getTrenningPlan()
      .then((response) => {
        commit('setResponseStatus', response.status);
        if (response.status === 200) {
          commit('setTrainingPlan', response.data);
        } else {
          commit('setTrainingPlan', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setResponseStatus', status || 0);
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
