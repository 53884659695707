<template>
  <div class="settings">
    <div v-if="!isMobileApp" class="divider"></div>
    <div v-if="!isMobileApp" class="settings__menu">
      <Menu />
      <div class="divider"></div>
    </div>
    <div class="settings__content">
      <div class="settings__wrapper">
        <div class="settings__nav">
        <div class="nav__club-info">
          <div class="club-info__avatar" :style="{'background-image': `url(${avatar})`}">
          </div>
          <div>
            <div class="club-info__name" :title="fullName" v-html="fullName"></div>
            <div class="club-info__link">Моя страница</div>
          </div>
        </div>
        <div class="divider nav__divider"></div>
        <div class="nav__select">
          <Select
            :options="filteredOptions"
            v-model="currentOption"
            :iconLeft="optionsData.filter((i) => i.id === currentOption)[0].icon"
            @input="onOptionSelected"
            class="d-md-none"
            mobile
          />
          <div
            v-for="item in filteredOptions"
            :key="item.id"
            class="nav__select-item"
            :class="{'nav__select-item--active': currentOption === item.id}"
            @click.stop="onOptionSelected(item.id)"
          >
            <Icon :name="item.icon" size="m" />
            <span v-html="item.name"></span>
          </div>
        </div>
        </div>
        <div v-if="!availabilityTrainingPlan && showTrainings" class="settings__training-plan">
          <div class="settings__training-plan__icon">
            <Icon name="rocket" size="xxl"/>
          </div>
          <p class="settings__training-plan__text">
            Тренируйтесь осмысленно с индивидуальным тренировочным планом
          </p>
          <Button
            variant="primary"
            icon-left="file-done"
            size="md"
            mobile
            @click="onModalTrainingsOpen"
          >Получить план</Button>
        </div>
      </div>
      <router-view v-bind="{ avatar }" />
    </div>
    <TrainingsModal
      v-if="!availabilityTrainingPlan"
      :active="modalTrainings"
      :isMobileApp="isMobileApp"
      @close="modalTrainings = false"
      @openModaInfo="onModalTrainingsInfoOpen"
      @startTest="onStartQuiz"
    />
    <TrainingsInfoModal
      v-if="!availabilityTrainingPlan"
      :active="modalTrainingsInfo"
      :isMobileApp="isMobileApp"
      @close="modalTrainingsInfo = false"
      @startTest="onStartQuiz"
    />
    <TrainingsFinishModal
      v-if="!availabilityTrainingPlan"
      :active="modalTrainingsFinish"
      @close="modalTrainingsFinish = false"
      @showPrice="onStartPrice"
    />
    <TrainingsPriceModal
      v-if="!availabilityTrainingPlan"
      :active="modalTrainingsPrice"
      @close="modalTrainingsPrice = false"
      @openPlan="modalTrainingsPrice = false"
      @openPlanCoach="modalTrainingsPrice = false"
      @clickSendTrenningsPurchase="clickSendTrenningsPurchase($event)"
    />
    <QuizModal
      v-if="!availabilityTrainingPlan"
      :active="quizModal"
      :options="quizOptions"
      @close="onQuizClose"
      @back="onQuizBack"
      @quizEnd="onQuizEnd"
    />
    <TrainingsSuccessModal
      v-if="!availabilityTrainingPlan && getEmail"
      :active="modalTrainingsSuccess"
      @close="closeModalTrainingsSuccess"
      :emailProfile="getEmail"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Select from '@rr-component-library/select/src/main';
import Menu from '@/components/Menu/Menu.vue';
import Avatars from '@/assets/data/avatars';
import QuizModal from '@/components/QuizModal/QuizModal.vue';
import TrainingsModal from '@/components/TrainingsModals/TrainingsModal.vue';
import TrainingsInfoModal from '@/components/TrainingsModals/TrainingsInfoModal.vue';
import TrainingsFinishModal from '@/components/TrainingsModals/TrainingsFinishModal.vue';
import TrainingsPriceModal from '@/components/TrainingsModals/TrainingsPriceModal.vue';
import TrainingsSuccessModal from '@/components/TrainingsModals/TrainingsSuccessModal.vue';

export default {
  name: 'Settings',
  components: {
    Select,
    Menu,
    QuizModal,
    TrainingsModal,
    TrainingsInfoModal,
    TrainingsFinishModal,
    TrainingsPriceModal,
    TrainingsSuccessModal,
  },
  data() {
    return {
      optionsData: [
        { id: 'SettingsProfile', name: 'Профиль', icon: 'user' },
        { id: 'SettingsTrainings', name: 'Тренировки', icon: 'time-limit' },
        { id: 'SettingsTrackers', name: 'Трекеры', icon: 'fitness-tracker' },
        { id: 'SettingsHistory', name: 'История покупок', icon: 'shopping-bag' },
        { id: 'SettingsPersonalization', name: 'Персонализация', icon: 'badge' },
      ],
      currentOption: 'SettingsTrainings',
      avatar: '',
      modalTrainings: false,
      modalTrainingsInfo: false,
      modalTrainingsFinish: false,
      modalTrainingsPrice: false,
      quizModal: false,
      modalTrainingsSuccess: false,
      quizOptions: null,
    };
  },
  computed: {
    ...mapGetters('user', ['profile', 'isMobileApp']),
    ...mapGetters('settings', ['options']),
    ...mapGetters('participant', ['trainingPlan']),

    fullName() {
      if (!this.profile) return '';
      return `${this.profile.firstName} ${this.profile.lastName}`;
    },
    routeName() {
      return this.$route.name;
    },
    filteredOptions() {
      if (this.options.questionnaire && this.options.questionnaire.length < 1) {
        return this.optionsData.filter((option) => option.name !== 'Персонализация');
      }
      return this.optionsData;
    },
    availabilityTrainingPlan() {
      return this.trainingPlan ? (Object.keys(this.trainingPlan).length !== 0) : false;
    },
    getEmail() {
      if (this.profile) {
        return this.profile.emailAddress;
      }
      return '';
    },
    showTrainings() {
      return this.options ? this.options.showTrainings === true : true;
    },
  },
  watch: {
    routeName() {
      if (this.$route.name === 'Settings') {
        this.onOptionSelected('SettingsTrainings');
      }
    },
  },
  methods: {
    ...mapActions('user', ['getProfile']),
    ...mapActions('wallet', ['getBalance']),
    ...mapActions('participant', ['sendTrennings', 'getTrainingPlan', 'sendTrenningsPurchase']),
    closeModalTrainingsSuccess() {
      this.modalTrainingsSuccess = false;
    },
    clickSendTrenningsPurchase(type) {
      this.sendTrenningsPurchase({ tariffName: type });
      this.getEmail();
      this.modalTrainingsSuccess = true;
    },

    onOptionSelected(option) {
      this.currentOption = option;
      this.$router.push({ name: option, query: this.isMobileApp ? { title: 'Мой аккаунт' } : {} }).catch(() => {});
    },
    getAvatarImage(avatar) {
      if (!avatar) return '';
      if (!avatar.type) return '';
      if (avatar.type === 'custom') {
        return avatar.url || '';
      }
      const found = Avatars.filter((i) => i.id === avatar.type);
      if (found.length === 0) return '';
      return found[0].img || '';
    },
    onModalTrainingsOpen() {
      this.modalTrainings = true;
    },
    onModalTrainingsInfoOpen() {
      if (this.modalTrainings) {
        this.modalTrainings = false;
        this.modalTrainingsInfo = true;
      }
    },
    onQuizEnd(data) {
      if (data.key) {
        this.quizModal = false;
        this.modalTrainingsFinish = true;
        this.sendTrennings({ trainingPlan: data.key });
        this.getTrainingPlan();
      }
    },
    onStartQuiz() {
      const options = {};
      options.quizId = 86257;
      if (options.quizId) {
        this.quizOptions = options;
        this.modalTrainings = false;
        this.modalTrainingsInfo = false;
        this.quizModal = true;
      }
    },
    onQuizClose() {
      this.quizModal = false;
    },
    onQuizBack() {
      this.quizModal = false;
    },
    onStartPrice() {
      this.modalTrainingsFinish = false;
      this.modalTrainingsPrice = true;
    },
  },
  mounted() {
    this.getProfile()
      .then(() => {
        this.getTrainingPlan();
        this.avatar = this.getAvatarImage(this.profile ? this.profile.avatar : null);
      });
    this.getBalance();
    if (this.$route.name === 'Settings') {
      this.onOptionSelected('SettingsProfile');
    } else if (this.$route.name === 'TrainingsTracker') {
      this.currentOption = 'SettingsTrainings';
    } else {
      this.currentOption = this.$route.name;
    }
  },
};
</script>

<style lang="scss" scoped>
  @import "./Settings";
</style>
