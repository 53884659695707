<template>
  <div class="purchase-card" v-if="options">
    <div class="purchase-card__image" :style="{ 'background-image': `url(${ image })` }"></div>
    <div class="purchase-card__text">
      <div class="purchase-card__title" v-html="title"></div>
      <div class="purchase-card__coins">
        {{ formatDate(date) + ' за ' + coins + ' баллов' }}
      </div>
    </div>
    <div class="divider" v-if="$root.isMobile"></div>
    <div class="purchase-card__code">
      <div class="purchase-card__code-value" @click.stop="$emit('how-to')">Промокод на {{ arrayPromocodes().length }} шт. <Icon name="arrow-right" size="xs" /></div>
      <!-- <div class="purchase-card__code-link" >Как получить товар?</div> -->
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'PurchaseCard',
  props: {
    options: Object,
  },
  data() {
    return {
    };
  },
  computed: {
    image() {
      return this.$props.options.img;
    },
    title() {
      return this.$props.options.title;
    },
    coins() {
      return this.$props.options.coins;
    },
    date() {
      return this.$props.options.date;
    },
    promocodes() {
      return this.$props.options.promocodes;
    },
  },
  methods: {
    formatDate() {
      if (!this.date) return '';
      const value = dayjs.utc(this.date).local();
      if (value.isValid()) {
        return `${value.format('DD MMMM')} в ${value.format('HH:mm')}`;
      }
      return '';
    },
    arrayPromocodes() {
      if (!this.promocodes) return [];
      return this.promocodes.split(',').map((code) => code.trim());
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "./PurchaseCard";
</style>
