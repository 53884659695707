<template>
  <div class="trainings-modal__finish">
    <Modal
      :active="active"
      name="TrainingsFinishModal"
      title="Ваша заявка отправлена"
      :subtitle="subtitleModalText"
      icon="check-circle"
      :class="{ 'for-mobile-app': isMobileApp }"
      @close.prevent="$emit('close')"
    >
      <Button
        variant="secondary"
        size="lg"
        mobile
        @click="$emit('close')"
      >
        Закрыть
      </Button>
    </Modal>
  </div>
</template>

<script>
export default {
  name: 'TrainingsSuccessModal',
  props: {
    active: Boolean,
    isMobileApp: Boolean,
    emailProfile: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      subtitleModalText: `Тренер свяжется с вами в ближайшее время по указанной в профиле почте <b>${this.emailProfile}</b>`,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./TrainingsSuccessModal";
</style>
