<template>
  <div class="mobile-link">
    <div class="mobile-overlay"></div>
  </div>
</template>

<script>
export default {
  name: 'MobileLogout',
  components: {},
};
</script>

<style lang="scss" scoped>
  @import "./MobileLogout";
</style>
