import api from './api';

export default {
  getOnboardingItems() {
    return api.get('/content/api/v1/onboarding');
  },
  getFAQThemes() {
    return api.get('/content/api/v1/faq/topics');
  },
  getFAQTheme({ topicId }) {
    return api.get(`/content/api/v1/faq/topics/${topicId}`);
  },
  getFAQThemeQuestions({ topicId }) {
    return api.get('/content/api/v1/faq/questions', { params: { topicId } });
  },
  getFAQThemeAnswers({ faqTopicId, faqQuestionId }) {
    return api.get('/content/api/v1/faq/questions/answer', { params: { faqTopicId, faqQuestionId } });
  },
  getRule() {
    return api.get('/content/api/v1/rules');
  },
  getAmbassadors() {
    return api.get('/content/api/v1/ambassadors');
  },
  getBanners() {
    return api.get('/content/api/v1/banners');
  },
  getIntroSections() {
    return api.get('/content/api/v1/intro-sections');
  },
};
