<template>
  <div class="trainings__plan">
    <Modal
      :active="active"
      name="TrainingsInfoModal"
      :class="{ 'for-mobile-app': isMobileApp }"
      @close.prevent="$emit('close')"
    >
      <div class="trainings-modal info">
        <p class="trainings-modal__title">Методика составления индивидуальных тренингов</p>
        <div class="trainings-modal__content">
          <p class="trainings-modal__subtitle">Общий подход</p>
          <p class="trainings-modal__text">Матрица прогресса позволяет бегунам отслеживать свои улучшения и видеть, как тренировки влияют на результат.</p>
          <p class="trainings-modal__text"> Например, улучшение времени на 5 км каждую неделю может указывать на эффективность тренировок. </p>
          <p class="trainings-modal__text">Также матрица помогает выявить слабые места, такие как недостаточная скорость на коротких дистанциях, и сосредоточиться на их улучшении.</p>
          <p class="trainings-modal__text"> Это полезный инструмент для бегунов всех уровней, способствующий постоянному росту результатов.</p>
          <p class="trainings-modal__subtitle">Правила построения тренировочных планов:</p>
          <ol class="trainings-modal__list">
            <li>Определите свои цели: Улучшение времени, увеличение дистанции или повышение уровня физической подготовки</li>
            <li>Учитывайте свой уровень подготовки: начинайте с легких тренировок и постепенно увеличивайте интенсивность.</li>
            <li>Разнообразьте тренировки: включайте интервальные, темповые и длительные пробежки для развития разных аспектов бега.</li>
            <li>Подстраивайте план под график: учтите свои обязательства при составлении плана.</li>
            <li>Отслеживайте прогресс: используйте инструменты для анализа улучшений.</li>
            <li>Самое главное в тренировках – научиться получать от них удовольствие. А если что-то не подходит в плане, его можно в любой момент пересмотреть и адаптировать лично для вас.</li>
          </ol>
        </div>
        <div class="trainings-modal__action">
          <Button
            variant="primary"
            size="md"
            mobile
            :wide="$root.isMobile"
            text="Начать тест"
            @click="$emit('startTest')"
          ></Button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  name: 'TrainingsInfoModal',
  props: {
    active: Boolean,
    isMobileApp: Boolean,
  },
  data() {
    return {
    };
  },

  computed: {

  },
  methods: {

  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
  @import './TrainingsModal';
</style>
