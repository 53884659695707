<template>
  <div class="trainings__plan">
    <Modal
      :active="active"
      name="TrainingsFinishModal"
      icon="file-done"
      :class="{ 'for-mobile-app': isMobileApp }"
      @close.prevent="$emit('close')"
    >
      <div class="trainings-modal finish">
        <p class="trainings-modal__title">Ваш тренировочный план готов!</p>
        <div class="trainings-modal__content">
          <p class="trainings-modal__text">Можете начать заниматься по нему самостоятельно, а можете обратиться к помощи тренера, чтобы учесть ваши индивидуальные особенности и сделать тренировки максимально результативными</p>
          <p v-if="!trainingPlan" class="trainings-modal__text">План всегда доступен <span @click="$router.push({ name: 'SettingsTrainings', query: isMobileApp ? { title: 'Мой аккаунт' } : {} })">в вашем личном кабинете</span></p>
          <!-- <img :src="profile ? profile.traningProgramImageUrl : ''" alt=""> -->
        </div>
        <div class="trainings-modal__action">
          <Button
            variant="primary"
            size="lg"
            mobile
            :target="!isMobileApp ? '_blank' : ''"
            tag="a"
            @click.stop="$emit('close')"
            :href="plan"
            :wide="$root.isMobile"
            icon-left="file-done"
            text="Открыть план"
          ></Button>
          <Button
            variant="secondary"
            size="lg"
            mobile
            @click="$emit('showPrice')"
            :wide="$root.isMobile"
            icon-right="right"
            text="Начать с тренером"
          ></Button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TrainingsFinishModal',
  props: {
    active: Boolean,
    isMobileApp: Boolean,
  },
  data() {
    return {
      plan: null,
    };
  },
  computed: {
    ...mapGetters('user', ['profile']),
    ...mapGetters('participant', ['trainingPlan']),
  },
  watch: {
    trainingPlan(newPlan) {
      if (newPlan) {
        this.plan = newPlan.trainingPlanUrl;
        // Здесь можно выполнить действия, когда trainingPlan изменится
        console.log('Training plan updated:', newPlan);
      }
    },
  },
  methods: {
    ...mapActions('participant', ['getTrainingPlan']),
  },
  mounted() {
    this.getTrainingPlan();
  },
};
</script>

<style lang="scss" scoped>
  @import './TrainingsModal';
</style>
