<template>
  <div class="trainings__price">
    <Modal
      :active="active"
      title="Тренируйтесь по плану при поддержке тренера"
      subtitle="Тренер анализирует данные, корректирует план и адаптирует будущие тренировки, исходя из вашего прогресса."
      name="TrainingsPriceModal"
      :class="{ 'for-mobile-app': isMobileApp }"
      @close.prevent="$emit('close')"
    >
      <div class="trainings__price-items">
        <div class="trainings__price-item">
          <div class="trainings__price-item__header">
            <p class="trainings__price-item__title">Самостоятельно</p>
            <div class="trainings__price-item__subtitle">без тренерской поддержки</div>
          </div>
          <div class="trainings__price-item__main">
            <p class="trainings__price-item__price">0 ₽</p>
            <Button
              variant="secondary"
              size="md"
              mobile
              :target="!isMobileApp ? '_blank' : ''"
              tag="a"
              @click.stop="$emit('close')"
              :href="trainingPlan ? trainingPlan.trainingPlanUrl : ''"
              :wide="$root.isMobile"
              text="Открыть план"
              icon-left="file-done"
              @click="$emit('startTest')"
            ></Button>
            <ul class="trainings__price-item__list">
              <li><Icon name="check-bold"></Icon><span>Тренировочный план</span></li>
            </ul>
          </div>
        </div>
        <div class="trainings__price-item trainings__price-item_orange">
          <div class="trainings__price-item__header">
            <p class="trainings__price-item__title">Консультация</p>
            <div class="trainings__price-item__subtitle">напутствие тренера</div>
          </div>
          <div class="trainings__price-item__main">
            <p class="trainings__price-item__price">Бесплатно</p>
            <Button
              variant="secondary"
              size="md"
              mobile
              :wide="$root.isMobile"
              text="Отправить заявку"
              @click="handleClick('Консультация')"
            ></Button>
            <ul class="trainings__price-item__list">
              <li><Icon name="check-bold"></Icon><span>Тренировочный план</span></li>
              <li><Icon name="check-bold"></Icon><span>15 минут консультаций в неделю голосом</span></li>
            </ul>
          </div>
        </div>
        <div class="trainings__price-item trainings__price-item_orange">
          <div class="trainings__price-item__header">
            <span class="trainings__price-item__badge">Популярно</span>
            <p class="trainings__price-item__title">Базовая</p>
            <div class="trainings__price-item__subtitle">тренерская поддержка</div>
          </div>
          <div class="trainings__price-item__main">
            <p class="trainings__price-item__price">2000 ₽ <span>/ месяц</span></p>
            <Button
              variant="primary"
              size="md"
              mobile
              :wide="$root.isMobile"
              text="Отправить заявку"
              @click="handleClick('Базовая')"
            ></Button>
            <ul class="trainings__price-item__list">
              <li><Icon name="check-bold"></Icon><span>1 раз в неделю обратная связь по прошедшим тренировкам</span></li>
              <li><Icon name="check-bold"></Icon><span>2 раза в месяц ответы на вопросы через почту, если они касаются плана тренировок</span></li>
            </ul>
          </div>
        </div>
        <div class="trainings__price-item trainings__price-item_orange">
          <div class="trainings__price-item__header">
            <p class="trainings__price-item__title">Безлимитная</p>
            <div class="trainings__price-item__subtitle">тренерская поддержка</div>
          </div>
          <div class="trainings__price-item__main">
            <p class="trainings__price-item__price">7900 ₽ <span>/ месяц</span></p>
            <Button
              variant="secondary"
              size="md"
              mobile
              :wide="$root.isMobile"
              text="Отправить заявку"
              @click="handleClick('Безлимитная')"
            ></Button>
            <ul class="trainings__price-item__list">
              <li><Icon name="check-bold"></Icon><span>персональный тренировочный план</span></li>
              <li><Icon name="check-bold"></Icon><span>безлимитная связь с тренером онлайн через мессенджеры/электронную почту</span></li>
              <li><Icon name="check-bold"></Icon><span>консультации по экипировке и инвентарю</span></li>
            </ul>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TrainingsFinishModal',
  props: {
    active: Boolean,
    isMobileApp: Boolean,
  },
  data() {
    return {
    };
  },

  computed: {
    ...mapGetters('user', ['profile']),
    ...mapGetters('participant', ['trainingPlan']),

  },
  methods: {
    handleClick(planType) {
      this.$emit('clickSendTrenningsPurchase', planType);
      this.$emit('close');
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
  @import './TrainingsModal';
</style>
