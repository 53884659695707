<template>
  <div v-if="themeDone" class="support">
    <div v-if="!isMobileApp" class="divider"></div>
    <div v-if="isAuthenticated === true && !isMobileApp" class="support__menu">
      <Menu />
      <div class="divider"></div>
    </div>

    <div class="support__intro">
      <div class="intro__questions"><img :src="questionsRafiki" /></div>
      <div class="intro__title" v-html="supportTitle"></div>
      <div class="intro__active-support"><img :src="activeSupportRafiki" /></div>
    </div>
    <div class="support__content">
      <div class="content__topics">
        <div class="topics__header">Выберите тему</div>
        <div class="topics__groups">
          <TopicCard
            v-for="group in groups"
            :key="group.id"
            :title="group.title"
            :isActive="group.id === groupSelected"
            isGroup
            class="topics__group"
            @select="onSelectGroup(group.id)"
          ></TopicCard>
        </div>
        <div class="divider"></div>
        <div v-if="groupSelected !== null" class="topics__selected" id="topics__selected">
          <div class="selected__title" v-html="selectedGroupTitle || ''"></div>
          <div class="selected__description"></div>
          <div class="selected__topics">
            <TopicCard
              v-for="topic in topics"
              :key="topic.faqQuestionId"
              :title="topic.title"
              :description="topic && topic.description ? topic.description : ''"
              :isActive="topic.faqQuestionId === topicSelected"
              class="selected__topic"
              @select="onSelectTopic(topic.faqQuestionId)"
            >
          </TopicCard>
          </div>
        </div>
        <div v-if="groupSelected !== null" class="divider"></div>
      </div>
      <div class="content__contact">
        <div class="contact__icon"><Icon name="mail" /></div>
        <div class="contact__title">Не нашли ответ на вопрос?</div>
        <div class="contact__action">
          <Button
            variant="secondary"
            size="lg"
            tag="a"
            mobile
            @click="openSupportDialog"
          >
            Задать вопрос
          </Button>
        </div>
      </div>
    </div>

    <SupportDialog
      :active="supportModal"
      @close="onSupportModalClose()"
      @form-submitted="handleFormSubmit"
    />

    <Modal
      :closable="true"
      name="successModal"
      :active="successModal"
      @close="onSuccessModalClose()"
    >
      <div class="modal__success">
        <Icon name="check-circle"></Icon>
        <p class="modal__success-title">Вопрос отправлен</p>
        <p class="modal__success-text">Обычно отвечаем в течение трёх рабочих дней. Ответ придёт на почту <span>{{ email }}</span> </p>
        <div class="moadal__success-action">
          <Button
            variant="secondary"
            size="lg"
            tag="a"
            mobile
            @click="onSuccessModalClose"
          >
            Закрыть
          </Button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { scrollToId, addNonBreakingSpaces } from '@/assets/js/helpers';
import Menu from '@/components/Menu/Menu.vue';
import TopicCard from '@/components/TopicCard/TopicCard.vue';
import SupportDialog from '@/components/SupportDialog/SupportDialog.vue';

export default {
  name: 'Support',
  components: {
    Menu,
    TopicCard,
    SupportDialog,
  },
  data() {
    return {
      groups: [],
      groupSelected: null,
      selectedGroupTitle: '',
      topics: [],
      topicSelected: null,
      supportModal: false,
      successModal: false,
      email: '',
    };
  },
  computed: {
    ...mapGetters('settings', ['themeDone', 'resources']),
    ...mapGetters('user', ['isAuthenticated', 'isMobileApp']),
    ...mapGetters('content', ['faqThemes', 'faqTheme', 'faqQuestions', 'faqAnswers', 'contentStatus', 'rule']),
    questionsRafiki() {
      return this.resources ? this.resources.questionsRafiki || '' : '';
    },
    activeSupportRafiki() {
      return this.resources ? this.resources.activeSupportRafiki || '' : '';
    },
    supportEmail() {
      return this.resources ? this.resources.supportEmail || '#' : '#';
    },
    supportEmailText() {
      return this.resources ? this.resources.supportEmailText || '' : '';
    },
    supportVK() {
      return this.resources ? this.resources.supportVK || '#' : '#';
    },
    supportReglament() {
      return this.rule ? this.rule.url || '#' : '#';
    },
    supportTitle() {
      return addNonBreakingSpaces('Часто задаваемые вопросы и ответы');
    },
  },
  methods: {
    ...mapActions('user', ['getProfile']),
    ...mapActions('wallet', ['getBalance']),
    ...mapActions('content', ['getFAQThemes', 'getFAQTheme', 'getFAQThemeQuestions', 'getFAQThemeAnswers', 'getRule']),
    ...mapActions('exercises', ['sendSupport']),
    openSupportDialog() {
      this.supportModal = true;
    },
    openSuccessDialog() {
      this.successModal = true;
    },
    onSupportModalClose() {
      this.supportModal = false;
    },
    onSuccessModalClose() {
      this.successModal = false;
    },
    handleFormSubmit(formData) {
      this.sendSupport(formData)
        .then(() => {
          this.email = formData.email;
          this.supportModal = false;
          this.successModal = true;
        });
    },
    onSelectGroup(id) {
      console.log('onSelectGroup', id);
      this.groupSelected = id;
      this.selectedGroupTitle = this.groups.filter((i) => i.id === id)[0].title;
      this.topicSelected = null;
      this.setTopics();
      setTimeout(() => scrollToId('topics__selected'), 250);
    },
    onSelectTopic(id) {
      console.log('onSelectTopic', id);
      if (this.topicSelected === id) {
        this.topicSelected = null;
        return;
      }
      const index = this.topics.findIndex((i) => i.faqQuestionId === id);
      if (index >= 0) {
        if (this.topics[index].description) {
          this.topicSelected = id;
          return;
        }
      }
      this.getFAQThemeAnswers({ faqTopicId: this.groupSelected, faqQuestionId: id })
        .then(() => {
          if (this.contentStatus === 200) {
            if (index >= 0) {
              console.log('this.faqAnswers', this.faqAnswers);
              this.topics[index].description = this.replaceVariables(this.faqAnswers.answer);
              this.topicSelected = id;
            }
          }
        });
    },
    loadThemes() {
      this.getFAQThemes()
        .then(() => {
          if (this.contentStatus === 200) {
            this.setGroups();
          }
        });
    },
    setGroups() {
      const groups = [];
      for (let i = 0; i < this.faqThemes.values.length; i += 1) {
        groups.push({ id: this.faqThemes.values[i].id, title: this.faqThemes.values[i].title });
      }
      this.groups = groups;
    },
    setTopics() {
      if (this.groupSelected === null) return;
      this.topics = [];
      this.getFAQTheme(this.groupSelected)
        .then(() => {
          if (this.contentStatus === 200) {
            this.getFAQThemeQuestions({ topicId: this.groupSelected })
              .then(() => {
                this.topics = this.faqQuestions ? this.faqQuestions.questions.values || [] : [];
              });
          }
        });
    },
    getDescription(answers) {
      let description = '';
      if (!answers) return '';
      for (let i = 0; i < answers.length; i += 1) {
        description += `<p>${answers[i].paragraph}</p>`;
      }
      return this.replaceVariables(description);
    },
    replaceVariables(text) {
      if (!text) return '';
      const vars = [
        { id: 'supportEmail', value: this.supportEmail },
        { id: 'supportEmailText', value: this.supportEmailText || this.supportEmail },
        { id: 'supportVK', value: this.supportVK },
        { id: 'supportReglament', value: this.supportReglament },
      ];
      let result = text;
      for (let i = 0; i < vars.length; i += 1) {
        result = result.replaceAll(`{{${vars[i].id}}}`, vars[i].value);
      }
      return result;
    },
  },
  mounted() {
    this.getRule();
    this.getProfile()
      .then(() => {
        if (this.isAuthenticated) {
          this.getBalance();
        }
      });
    this.loadThemes();
  },
};
</script>

<style lang="scss" scoped>
  @import "./Support";
</style>
