<template>
  <div class="profile">
    <div class="profile__avatar-account">
      <div class="profile__avatar">
        <div class="avatar">
          <div class="avatar__image" :style="{ 'background-image': `url(${avatar})` }"></div>
          <div class="avatar__edit" @click.stop="onSetAvatar">
            <Icon name="camera" size="l" />
          </div>
        </div>
      </div>
      <div class="profile__account">
        <div class="account__name" v-html="`${profile ? profile.firstName : ''} ${profile ? profile.lastName : ''}`"></div>
        <div class="account__details">
          <div>{{ questionnaire ? getValue(genders, getValue(questionnaire.answers, 'Gender')) : '' }} · {{ questionnaire ? formatAge(getValue(questionnaire.answers, 'Birthday')) : '' }}</div>
          <div>{{ questionnaire ? getValue(questionnaire.answers, 'EmailAddress') : '' }}</div>
        </div>
        <div class="account__change-email">
          <Link variant="secondary" size="sm" text="Изменение данных учётной записи" iconLeft="info-circle" @click.prevent="onChangePersonal" />
        </div>
      </div>

    </div>
    <span v-if="!socialLinks" class="profile__blocked" @click="openBlockedModal">Удалить профиль → </span>

    <div v-if="socialLinks">
      <form
        novalidate="novalidate"
        @submit.prevent="onSaveSocialLInk"
        class="profile__form">
        <div class="profile__form-item">
          <Field
            label="VK"
            label-for="vk"
            row
          >
          <div class="profile__form-inner">
            <Input
              v-model="fields.accountVk"
              placeholder="https://vk.com/ivan_ivanov"
              id="vk"
              name="vk"
              :state="
                $v.fields.accountVk.$error ? 'error' : null
              "
              @input="$v.fields.accountVk.$touch()"
            />
            <p class="profile__form-link" @click="onChangeVk">Где найти?</p>
          </div>
          </Field>
        </div>
        <div class="profile__form-item">
          <Field
            label="Telegram"
            label-for="telegram"
            row
          >
          <div class="profile__form-inner">
            <Input
              v-model.trim="fields.accountTelegram"
              placeholder="ivanov"
              id="telegram"
              name="telegram"
              :icon-left="!isMobileApp ? 'atmark' : ''"
              :state="
                $v.fields.accountTelegram.$error ? 'error' : null
              "
              @input="$v.fields.accountTelegram.$touch()"

            />
            <p class="profile__form-link" @click="onChangeTelegram">Где найти?</p>
          </div>
          </Field>
        </div>
        <div class="error-msg">
          <div class="color-danger" v-if="$v.fields.accountVk.$error">Поле должно быть типа: https://vk.com/ivan</div>
          <div class="color-danger" v-if="$v.fields.accountTelegram.$error">Поле должно быть типа: ivanov</div>
        </div>
        <div class="divider"></div>
        <p class="profile__form-text">По желанию добавьте ссылки на свои профили в социальных сетях, таких как ВКонтакте и/или Телеграм. В разделе "<span @click="$router.push({ name: 'Participants', query: isMobileApp ? { title: 'Участники' } : {} }).catch(() => {})" >Участники</span>"  можно найти профили других участников Программы в социальных сетях</p>
        <div class="profile__action ">
          <div>
            <span class="profile__blocked" @click="openBlockedModal">Удалить профиль → </span>
          </div>

          <Button
            v-if="showAnswers"
            variant="primary"
            size="lg"
            mobile
            :wide="$root.isMobile ? true : false"
            type="submit"
            :disabled="hasErrors || $v.fields.accountVk.$error || $v.fields.accountTelegram.$error"
          >
            Сохранить
          </Button>
        </div>
        <div class="alert">
          <Alert v-if="showSuccess" state="success" noAction autoClose closable>
            Изменения сохранены.
          </Alert>
          <Alert v-if="showError" state="error" noAction autoClose closable>
            Произшла ошибка.
          </Alert>
        </div>
        <div v-if="showSuccessText">
          <p v-if="profileCommunications ? profileCommunications.accounts.vk || profileCommunications.accounts.telegram : null" class="profile__form-text center">Теперь участники программы смогут найти вас в социальных сетях. <br> Перейти в раздел "<span @click="$router.push({ name: 'Participants', query: isMobileApp ? { title: 'Участники' } : {} }).catch(() => {})">Участники</span>"</p>
        </div>
      </form>
    </div>
    <Modal
      name="blocked-modal"
      title="Удалённый профиль нельзя восстановить, вы уверены?"
      subtitle="Вместе с профилем пропадут: история тренировок, заработанные баллы, очки рейтинга, история покупок и вся персональная информация о вас."
      icon="exclamation-circle"
      :active="blockedModal"
      :noTransition="true"
      :class="{ 'for-mobile-app': isMobileApp }"
      @close.prevent="blockedModal=false"
    >
    <div class="profile__block">
      <div class="profile__modal-action">
        <Button
          v-if="showAnswers"
          variant="secondary"
          size="lg"
          mobile
          @click="blockedModal=false"
        >
          Отмена
        </Button>
        <Button
          v-if="showAnswers"
          variant="primary"
          size="lg"
          mobile
          @click="changeBlockedProfile"
        >
          Удалить профиль
        </Button>
      </div>
    </div>
    </Modal>
    <Modal
      name="change-vk"
      title=" Как найти ссылку пользователя ВК в мобильном приложении?"
      :active="showChangeVk"
      :noTransition="true"
      :class="{ 'for-mobile-app': isMobileApp }"
      @close.prevent="onChangeVkClosed"
    >
    <div class="profile__block">
      <ol class="profile__list">
        <li>Перейдите на <a href="https://m.vk.com/" target="_blank" class="color">страницу сайта ВК</a></li>
        <li>Нажмите на фото профиля в левом верхнем углу</li>
        <li>В выпадающем меню выберите «Скопировать ссылку»</li>
        <li>Вставьте ссылку в формате <span>https://vk.com/ivan_ivanov</span></li>
      </ol>
      <div class="profile__modal-imgs">
        <div class="profile__modal-img">
          <img src="/img/vk.jpg" alt="">
        </div>
        <div class="profile__modal-img">
          <img src="/img/vk-ios.png" alt="">
        </div>
      </div>
      <h2 class="profile__list-title">Как найти ссылку пользователя ВК в веб-версии? </h2>
      <ol class="profile__list">
        <li>Перейдите на <a href="https://vk.com/" target="_blank" class="color">страницу сайта ВК</a></li>
        <li>Авторизируйтесь, скопируйте ссылку в строке браузера </li>
        <li>Вставьте ссылку в формате <span>https://vk.com/ivan_ivanov</span>  </li>
      </ol>
      <div class="profile__modal-action">
        <Button
          v-if="showAnswers"
          variant="secondary"
          size="lg"
          mobile
          :wide="$root.isMobile ? true : false"
          @click="onChangeVkClosed"
        >
          Продолжить
        </Button>
      </div>
    </div>
    </Modal>
    <Modal
      name="change-telegram"
      title="Где найти имя пользователя в приложении Telegram"
      :active="showChangeTelegram"
      :noTransition="true"
      :class="{ 'for-mobile-app': isMobileApp }"
      @close.prevent="onChangeTelegramClosed"
    >
    <div class="profile__block">
      <ol class="profile__list">
        <li>Откройте приложение Телеграм</li>
        <li>Перейдите в раздел "Настройки"</li>
        <li>Скопируйте содержимое поля "Имя пользователя"</li>
        <li>Вставьте в формате ivanov</li>
      </ol>
      <div class="profile__modal-imgs">
        <div class="profile__modal-img">
          <img src="/img/telegram.jpg" alt="">
        </div>
        <div class="profile__modal-img">
          <img src="/img/telegram-ios.jpg" alt="">
        </div>
      </div>
      <div class="profile__modal-action">
        <Button
          v-if="showAnswers"
          variant="secondary"
          size="lg"
          mobile
          :wide="$root.isMobile ? true : false"
          @click="onChangeTelegramClosed"
        >
          Продолжить
        </Button>
      </div>
    </div>
    </Modal>
    <Modal
      name="change-personal"
      icon="user-edit"
      title="Изменение данных учётной записи"
      :subtitle="`Согласно <a href='${docOferta}' target='_blank'>договору оферте</a> данные учетной записи не доступны для редактирования. Если вы нашли ошибку или сменили имя, напишите нам.`"
      :active="showChangePersonal"
      :noTransition="true"
      :class="{ 'for-mobile-app': isMobileApp }"
      @close.prevent="onChangePersonalClosed"
    >
      <div class="buttons-group">
        <div class="buttons-group__item">
          <Button
            variant="secondary"
            size="lg"
            text="Написать в поддержку"
            mobile
            @click="onSupport"
          ></Button>
        </div>
      </div>
    </Modal>

    <SupportDialog
      :active="supportModal"
      :isMobileApp="isMobileApp"
      @close="onSupportClosed"
      @form-submitted="handleFormSubmit"
    />

    <Modal
      :closable="true"
      name="successModal"
      :active="successModal"
      :class="{ 'for-mobile-app': isMobileApp }"
      @close="onSuccessClosed"
      >
      <div class="modal__success">
        <Icon name="check-circle"></Icon>
        <p class="modal__success-title">Вопрос отправлен</p>
        <p class="modal__success-text">Обычно отвечаем в течение трёх рабочих дней. Ответ придёт на почту <span>{{ email }}</span> </p>
        <div class="moadal__success-action">
          <Button
            variant="secondary"
            size="lg"
            tag="a"
            mobile
            @click="onSuccessClosed"
          >
            Закрыть
          </Button>
        </div>
      </div>
    </Modal>

    <SetAvatarModal
      :active="showSetAvatar"
      :isMobileApp="isMobileApp"
      @close="onSetAvatarClosed"
    ></SetAvatarModal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { helpers } from 'vuelidate/lib/validators';
import dayjs from 'dayjs';
import Field from '@rr-component-library/field/src/main';
import Input from '@rr-component-library/input/src/main';
import Alert from '@rr-component-library/alert/src/main';
import SupportDialog from '@/components/SupportDialog/SupportDialog.vue';
import SetAvatarModal from '@/components/SetAvatarModal/SetAvatarModal.vue';

const accountVkRegex = /^https?:\/\/vk\.com\/[a-zA-Z0-9+:-_.-]+$/;
const accountTelegramRegex = /^(?!https?:\/\/)[^@][a-zA-Z0-9_.-]+$/;
// const accountTelegramRegex = /^[^@][a-zA-Z0-9_]+$/;
const customParseFormat = require('dayjs/plugin/customParseFormat');

dayjs.extend(customParseFormat);

export default {
  name: 'Profile',
  mixins: [validationMixin],
  components: {
    SetAvatarModal,
    SupportDialog,
    Field,
    Input,
    Alert,
  },
  props: {
    avatar: String,
  },
  data() {
    return {
      fields: {
        accountVk: '',
        accountTelegram: '',
      },
      genders: [
        {
          value: 'Мужчина',
          key: 'Male',
        },
        {
          value: 'Женщина',
          key: 'Female',
        },
      ],
      items: [],
      answered: [],
      answers: [],
      showAnswers: false,
      showSuccess: false,
      showSuccessText: false,
      showError: false,
      showChangePersonal: false,
      showSetAvatar: false,
      saving: false,
      supportModal: false,
      successModal: false,
      blockedModal: false,
      email: '',
      isHashFirstCheck: true,
      showChangeVk: false,
      showChangeTelegram: false,
      showErrors: false,
    };
  },
  validations: {
    fields: {
      // accountTelegram: { required, between: between(18, 45), url },
      // accountVk: { required, between: between(18, 45), url },
      accountVk: { isValidAccountVk: helpers.regex('accountVk', accountVkRegex) },
      accountTelegram: { isValidAccountTelegram: helpers.regex('accountTelegram', accountTelegramRegex) },
    },
  },
  computed: {
    ...mapGetters('settings', ['theme', 'themeDone', 'options', 'resources']),
    ...mapGetters('user', ['profile', 'questionnaire', 'questionnaireStatus', 'isMobileApp']),
    ...mapGetters('communications', ['communications', 'responseStatus', 'profileCommunications']),
    hasErrors() {
      return (this.showErrors && this.$v.$anyError);
    },
    questions() {
      return this.options ? this.options.questionnaire || [] : [];
    },
    supportEmail() {
      return this.resources ? this.resources.supportEmail || '#' : '#';
    },
    socialLinks() {
      return this.options ? this.options.socialLinks || false : false;
    },
    docOferta() {
      return this.resources ? this.resources.docOferta || '#' : '#';
    },
  },
  watch: {
    themeDone(val) {
      if (val) {
        this.setItems();
        this.setAnswers();
      }
    },
  },
  methods: {
    ...mapActions('wallet', ['getBalance']),
    ...mapActions('user', ['getQuestionnaire', 'applyAnswers', 'profileBlocked']),
    ...mapActions('exercises', ['sendSupport']),
    ...mapActions('communications', ['createSocialAccount', 'getProfileCommunications']),

    onSaveSocialLInk() {
      this.$v.$touch();
      if (!this.$v.$anyError) {
        const data = {
          accountVk: this.fields.accountVk,
          accountTelegram: this.fields.accountTelegram,
        };
        this.createSocialAccount(data)
          .then(() => {
            if (this.responseStatus === 204) {
              this.showSuccess = true;
              this.showSuccessText = true;
              this.getProfileCommunication();
              setTimeout(() => {
                this.showSuccess = false;
              }, 2000);
            } else {
              this.showError = false;
            }
          });
      }
    },
    getProfileCommunication() {
      this.getProfileCommunications()
        .then(() => {
          if (this.profileCommunications) {
            this.fields.accountVk = this.profileCommunications.accounts.vk;
            this.fields.accountTelegram = this.profileCommunications.accounts.telegram;
          }
        })
        .catch(() => {
          this.showError = true;
        });
    },
    onHashChange() {
      if (this.$route.name !== 'SettingsProfile') {
        window.removeEventListener('hashchange', this.onHashChange);
        return;
      }
      const hash = window.location.hash.split('=');
      if (hash[0] === '#!showChangePersonal') this.showChange(hash);
      else if (hash[0] === '#!showSetAvatar') this.showAvatar(hash);
      else if (hash[0] === '#!showSupport') this.showSupport(hash);
      else if (hash[0] === '#!showSupportSuccess') this.showSupportSuccess(hash);
      else if (hash[0] === '#!showChangeVk') this.showChangeV(hash);
      else if (hash[0] === '#!showChangeTelegram') this.showChangeT(hash);
      else {
        this.clearHash();
        this.showChangePersonal = false;
        this.showSetAvatar = false;
        this.supportModal = false;
        this.successModal = false;
        this.showChangeVk = false;
        this.showChangeTelegram = false;
      }
    },
    onChangeTelegram() {
      window.location.hash = '#!showChangeTelegram=true';
    },
    onChangeTelegramClosed() {
      window.location.hash = '#!showChangeTelegram=false';
    },
    onChangeVk() {
      window.location.hash = '#!showChangeVk=true';
    },
    onChangeVkClosed() {
      window.location.hash = '#!showChangeVk=false';
    },
    onChangePersonal() {
      this.supportModal = false;
      window.location.hash = '#!showChangePersonal=true';
    },
    onChangePersonalClosed() {
      window.location.hash = '#!showChangePersonal=false';
    },
    onSetAvatar() {
      window.location.hash = '#!showSetAvatar=true';
    },
    onSetAvatarClosed() {
      window.location.hash = '#!showSetAvatar=false';
    },
    onSupport() {
      this.showChangePersonal = false;
      window.location.hash = '#!showSupport=true';
    },
    onSupportClosed() {
      window.location.hash = '#!showSupport=false';
    },
    onSuccessClosed() {
      window.location.hash = '#!showSupportSuccess=false';
    },
    handleFormSubmit(formData) {
      this.supportModal = false;
      window.location.hash = '#!showSupport=false';
      this.sendSupport(formData)
        .then(() => {
          this.email = formData.email;
          window.location.hash = '#!showSupportSuccess=true';
        });
    },
    setItems() {
      this.items = this.questions;
      this.answered = new Array(this.items.length).fill(false);
      this.answers = new Array(this.items.length).fill(null);
    },
    setAnswers() {
      for (let i = 0; i < this.answers.length; i += 1) {
        this.answers[i] = this.getNumber(this.getValue(this.questionnaire.answers, `Question${i + 1}`));
        this.answered[i] = this.answers[i] !== null;
      }
      this.showAnswers = true;
    },
    getValue(answers, key) {
      if (!answers) return '';
      const found = answers.filter((i) => i.key === key);
      if (found.length === 0) return '';
      return found[0].value;
    },
    getNumber(value) {
      try {
        const v = Number.parseInt(value, 10);
        return v >= 0 ? v : null;
      } catch {
        return null;
      }
    },
    formatAge(date) {
      const d = dayjs.utc(date, 'YYYY-MM-DD', true);
      if (d.isValid()) {
        const seconds = dayjs.utc().diff(d, 'seconds');
        const duration = dayjs.duration(seconds * 1000);
        const years = Number.parseInt(duration.asYears(), 10);
        return `${years} лет`;
      }
      return '';
    },
    showChange(hash) {
      if (hash[1] === 'true') {
        this.supportModal = false;
        this.showChangePersonal = true;
      } else {
        this.showChangePersonal = false;
        this.clearHash();
      }
    },
    showChangeV(hash) {
      if (hash[1] === 'true') {
        this.supportModal = false;
        this.showChangeVk = true;
      } else {
        this.showChangeVk = false;
        this.clearHash();
      }
    },
    showChangeT(hash) {
      if (hash[1] === 'true') {
        this.supportModal = false;
        this.showChangeTelegram = true;
      } else {
        this.showChangeTelegram = false;
        this.clearHash();
      }
    },
    showAvatar(hash) {
      if (hash[1] === 'true') this.showSetAvatar = true;
      else {
        this.showSetAvatar = false;
        this.clearHash();
      }
    },
    showSupport(hash) {
      if (hash[1] === 'true') this.supportModal = true;
      else {
        this.supportModal = false;
        this.clearHash();
      }
    },
    showSupportSuccess(hash) {
      if (hash[1] === 'true') this.successModal = true;
      else {
        this.successModal = false;
        this.clearHash();
      }
    },
    clearHash() {
      setTimeout(() => { window.location.hash = ''; }, 100);
    },
    openBlockedModal() {
      this.blockedModal = true;
    },
    changeBlockedProfile() {
      this.profileBlocked()
        .then(() => {
          this.blockedModal = false;
        });
    },

  },
  mounted() {
    this.getProfileCommunication();
    window.addEventListener('hashchange', this.onHashChange);
    this.onHashChange();

    this.getQuestionnaire()
      .then(() => {
        if (this.themeDone) {
          this.setItems();
          this.setAnswers();
        }
      });
  },
  beforeDestroy() {
    window.removeEventListener('hashchange', this.onHashChange);
  },
};
</script>

<style lang="scss" scoped>
  @import "./Profile";
</style>
