<template>
  <div class="trainings__plan">
    <Modal
      :active="active"
      name="TrainingsModal"
      :class="{ 'for-mobile-app': isMobileApp }"
      @close.prevent="$emit('close')"
    >
      <div class="trainings-modal">
        <div class="trainings-modal__img">
          <img :src="stepsRafiki" alt="" />
        </div>
        <div class="trainings-modal__content">
          <p class="trainings-modal__title">Пройдите установочный тест</p>
          <p class="trainings-modal__text">С помощью него мы сможем выявить ваше текущее состояние, определить цель тренировок и подобрать подходящий план физической активности.</p>
          <p @click="$emit('openModaInfo')" class="trainings-modal__link">Подробнее про методику</p>
        </div>
        <div class="trainings-modal__action">
          <Button
            variant="primary"
            size="md"
            mobile
            :wide="$root.isMobile"
            text="Начать тест"
            @click="$emit('startTest')"
          ></Button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TrainingsModal',
  props: {
    active: Boolean,
    localizedText: Object,
    isMobileApp: Boolean,
  },
  data() {
    return {
      image: Image,
    };
  },

  computed: {
    ...mapGetters('settings', ['resources']),
    stepsRafiki() {
      return this.resources ? this.resources.stepsRafiki || '' : '';
    },

  },
  methods: {

  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
  @import './TrainingsModal';
</style>
