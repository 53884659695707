<template>
  <div
    v-if="themeDone"
    class="rules-prizes"
    :class="{'rules-prizes--personal': state === 'personal'}"
  >
    <Modal
      :active="active"
      name="RulesPrizesModal"
      :class="{
        'rules-prizes_team': isTeam,
        'for-mobile-app': isMobileApp,
      }"
      :title="title"
      :subtitle="subtitle"
      :img="image"
      :closable="true"
      @close="$emit('close')"
    >
      <div v-if="!isMobileApp || showBack" class="rules-prizes__back">
        <Button
          variant="secondary"
          :size="$root.isMobile ? 'sm' : 'lg'"
          shape="circle"
          iconLeft="left"
          mobile
          @click="$emit('close')"
        ></Button>
      </div>
      <div class="rules-prizes__container">
        <div class="rules-prizes__tabs">
          <Tabs :items="tabs" :activeItem="selectedTab" @changed="changeTab" />
        </div>
        <div class="rules-prizes__divider"></div>
        <div v-if="selectedTab !== 2" class="rules-prizes__content text-left" v-html="tabs[selectedTab] ? replaceVariables(tabs[selectedTab].content) || '' : ''"></div>
        <div v-else class="rules-prizes__content rules-prizes__content--rating text-left">
          <div v-if="false" class="rules-prizes__types">
            <Button
              v-for="(item, i) in types"
              :key="i"
              :variant="i === selectedType ? 'primary' : 'secondary'"
              size="md"
              mobile
              @click="changeType(i)"
            >
              {{ item }}
            </Button>
          </div>
          <div class="rules-prizes__entries">
            Показаны {{ usingPagination ? showItemsOffset + 1 : showItemsOffsetForMore + 1 }}–{{ usingPagination ? (currentPage === totalPages ? totalItems : showItemsOffset + 10) : showItemsOffsetForMore + showItemsCount }} из {{ totalItems }} записей
          </div>
          <div v-if="state !== 'personal'" class="rules-prizes__table">
            <table>
              <tr
                v-for="(item, i) in ratingItems"
                :key="i"
                class="rules-prizes__rating-item"
              >
                <td class="rating-item__number">{{ item.number }}</td>
                <td class="rating-item__name">{{ item.name }}</td>
                <td class="rating-item__value">
                  <div>
                    {{ item.value }}<img :src="pointImage" width="18" height="18"/>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div v-if="state === 'personal'" class="rules-prizes__table rules-prizes__table--personal">
            <table>
              <tr
                v-for="(item, i) in ratingItems"
                :key="i"
                class="rules-prizes__rating-item"
                :class="{ 'rating-item--active': item.current === true }"
              >
                <td class="rating-item__number" v-if="item.change === 'up'">{{ item.number }}<span class="number--up">&#8239;&#8593;</span></td>
                <td class="rating-item__number" v-else-if="item.change === 'down'">{{ item.number }}<span class="number--down">&#8239;&#8595;</span></td>
                <td class="rating-item__number" v-else>{{ item.number }}</td>
                <td class="rating-item__name">{{ item.name }}</td>
                <td class="rating-item__value">
                  <div>
                    {{ item.value }}<img :src="pointImage" width="18" height="18"/>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <Button
            v-if="ratingItems.length < totalItems && currentPage !== totalPages"
            variant="secondary"
            size="md"
            mobile
            :wide="true"
            class="rules-prizes__more"
            @click="onMore"
          >
            Показать ещё
          </Button>
          <Pagination
            :currentPage="currentPage"
            :totalPages="totalPages"
            class="rules-prizes__pagination"
            @prev-page="onChangePage(currentPage - 1)"
            @next-page="onChangePage(currentPage + 1)"
            @go-page="onChangePage"
          ></Pagination>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Pagination from '../Pagination/Pagination.vue';
import Point from '@/assets/img/point.svg';

export default {
  name: 'RulesPrizesModal',
  props: {
    active: Boolean,
    image: String,
    title: String,
    subtitle: String,
    nominationId: String,
    isTeam: Boolean,
    state: String,
    tabs: Array,
    activeTab: Number,
    isMobileApp: Boolean,
    showBack: Boolean,
  },
  components: {
    Pagination,
  },
  data() {
    return {
      types: ['Общий зачёт', 'Категория'],
      selectedType: 0,
      selectedTab: 0,
      pointImage: Point,
      currentPage: 1,
      totalPages: 0,
      totalItems: 0,
      ratingItems: [],
      showItemsCount: 10,
      showItemsOffsetForMore: 0,
      usingPagination: true,
      offset: 0,
    };
  },
  computed: {
    ...mapGetters('settings', ['theme', 'themeDone', 'resources']),
    ...mapGetters('nominations', ['nominationResults', 'teamNominationResults']),
    ...mapGetters('user', ['profile']),
    ...mapGetters('content', ['rule']),
    showItemsOffset() {
      return (this.currentPage - 1) * 10;
    },
    teamId() {
      return this.$props.isTeam && this.myTeamId ? this.myTeamId : '';
    },
    myTeamId() {
      return this.profile ? this.profile.team && this.profile.team.teamId : '';
    },
    supportEmail() {
      return this.resources ? this.resources.supportEmail || '#' : '#';
    },
    supportVK() {
      return this.resources ? this.resources.supportVK || '#' : '#';
    },
    supportReglament() {
      return this.rule ? this.rule.url || '#' : '#';
    },
  },
  watch: {
    active() {
      if (this.active) {
        this.selectedTab = this.$props.activeTab;
        if (this.selectedTab === 2) {
          this.loadRating();
        }
      } else {
        this.selectedTab = 0;
        this.currentPage = 1;
        this.totalPages = 0;
        this.totalItems = 0;
        this.ratingItems = [];
        this.showItemsCount = 10;
        this.offset = 0;
      }
    },
    activeTab() {
      this.selectedTab = this.$props.activeTab;
      if (this.selectedTab === 2) {
        this.loadRating();
      }
    },
  },
  methods: {
    ...mapActions('nominations', ['getNominationResults', 'getTeamNominationResults']),
    ...mapActions('content', ['getRule']),
    changeType(index) {
      this.selectedType = index;
      this.$emit('type-changed', index);
    },
    changeTab(index) {
      this.selectedTab = index;
    },
    onChangePage(page) {
      if (this.currentPage === page) return;
      this.currentPage = page;
      this.showItemsCount = 10;
      this.showItemsOffsetForMore = 0;
      this.usingPagination = true;
      this.loadRatingPage();
    },
    onMore() {
      // eslint-disable-next-line prefer-destructuring
      let showItemsOffsetForMore = this.showItemsOffsetForMore;
      if (this.usingPagination && this.currentPage > 1) {
        if (showItemsOffsetForMore === 0) {
          showItemsOffsetForMore = this.showItemsOffset;
        }
      }
      let showItemsCount = this.showItemsCount + 10;
      if (showItemsCount > this.totalItems) {
        showItemsCount = this.totalItems;
      }
      if (showItemsOffsetForMore + showItemsCount > this.totalItems) {
        showItemsCount = this.totalItems - showItemsOffsetForMore;
      }
      this.showItemsOffsetForMore = showItemsOffsetForMore;
      this.showItemsCount = showItemsCount;
      this.currentPage = Math.ceil((showItemsOffsetForMore + showItemsCount) / 10);
      this.usingPagination = false;
      if (!this.isTeam) {
        this.getNominationResults({
          nominationId: this.nominationId, teamId: this.teamId, offset: this.showItemsOffset, count: 10,
        })
          .then(() => {
            if (this.nominationResults) {
              this.ratingItems.push(...this.getRating(this.nominationResults.values));
            }
          });
      } else {
        this.getTeamNominationResults({
          nominationId: this.nominationId, offset: this.showItemsOffset, count: 10,
        })
          .then(() => {
            if (this.teamNominationResults) {
              this.ratingItems.push(...this.getRating(this.teamNominationResults.values));
            }
          });
      }
    },
    loadRating() {
      if (!this.isTeam) {
        this.getNominationResults({
          nominationId: this.nominationId, teamId: this.teamId, offset: 0, count: 10,
        })
          .then(() => {
            if (this.nominationResults) {
              this.ratingItems = this.getRating(this.nominationResults.values);
              this.totalPages = Math.ceil(this.nominationResults.total / 10);
              this.totalItems = this.nominationResults.total;
            }
          });
      } else {
        this.getTeamNominationResults({ nominationId: this.nominationId, offset: 0, count: 10 })
          .then(() => {
            if (this.teamNominationResults) {
              this.ratingItems = this.getRating(this.teamNominationResults.values);
              this.totalPages = Math.ceil(this.teamNominationResults.total / 10);
              this.totalItems = this.teamNominationResults.total;
            }
          });
      }
    },
    loadRatingPage() {
      this.offset = (this.currentPage - 1) * 10;
      if (!this.isTeam) {
        this.getNominationResults({
          nominationId: this.nominationId, teamId: this.teamId, offset: this.offset, count: 10,
        })
          .then(() => {
            if (this.nominationResults) {
              this.ratingItems = this.getRating(this.nominationResults.values);
              this.totalPages = Math.ceil(this.nominationResults.total / 10);
              this.totalItems = this.nominationResults.total;
            }
          });
      } else {
        this.getTeamNominationResults({ nominationId: this.nominationId, offset: this.offset, count: 10 })
          .then(() => {
            if (this.teamNominationResults) {
              this.ratingItems = this.getRating(this.teamNominationResults.values);
              this.totalPages = Math.ceil(this.teamNominationResults.total / 10);
              this.totalItems = this.teamNominationResults.total;
            }
          });
      }
    },
    getRating(values) {
      if (!values) return [];
      const rating = [];
      for (let i = 0; i < values.length; i += 1) {
        const it = {
          number: values[i].position.toString(),
          name: this.isTeam === true ? values[i].teamName || '' : `${values[i].lastName} ${values[i].firstName}`,
          value: values[i].points.toString(),
          current: this.isTeam === true ? values[i].isCurrentTeam === true : values[i].isCurrentUser === true,
        };
        rating.push(it);
      }
      return rating;
    },
    replaceVariables(text) {
      if (!text) return '';
      const vars = [
        { id: 'supportEmail', value: this.supportEmail },
        { id: 'supportVK', value: this.supportVK },
        { id: 'supportReglament', value: this.supportReglament },
      ];
      let result = text;
      for (let i = 0; i < vars.length; i += 1) {
        result = result.replaceAll(`{{${vars[i].id}}}`, vars[i].value);
      }
      return result;
    },
  },
  mounted() {
    this.getRule();
    this.selectedTab = this.$props.activeTab;
  },
};
</script>

<style lang="scss" scoped>
  @import './RulesPrizesModal';
</style>
