/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import api from '@/services/content-api';

const state = {
  onboardingItems: null,
  faqThemes: null,
  faqTheme: null,
  faqQuestions: null,
  faqAnswers: null,
  contentStatus: null,
  rule: null,
  ambassadors: null,
  banners: null,
  introSections: null,
};

const mutations = {
  setOnboardingItems(state, value) {
    state.onboardingItems = value;
  },
  setFAQThemes(state, value) {
    state.faqThemes = value;
  },
  setFAQTheme(state, value) {
    state.faqTheme = value;
  },
  setFAQQuestions(state, value) {
    state.faqQuestions = value;
  },
  setFAQAnswers(state, value) {
    state.faqAnswers = value;
  },
  setContentStatus(state, value) {
    state.contentStatus = value;
  },
  setRule(state, value) {
    state.rule = value;
  },
  setAmbassadors(state, value) {
    state.ambassadors = value;
  },
  setBanners(state, value) {
    state.banners = value;
  },
  setIntroSections(state, value) {
    state.introSections = value;
  },
};

const getters = {
  onboardingItems: (state) => state.onboardingItems,
  faqThemes: (state) => state.faqThemes,
  faqTheme: (state) => state.faqTheme,
  faqQuestions: (state) => state.faqQuestions,
  faqAnswers: (state) => state.faqAnswers,
  contentStatus: (state) => state.contentStatus,
  rule: (state) => state.rule,
  ambassadors: (state) => state.ambassadors,
  banners: (state) => state.banners,
  introSections: (state) => state.introSections,
};

const actions = {
  getOnboardingItems({ commit }) {
    return api.getOnboardingItems()
      .then((response) => {
        commit('setContentStatus', response.status);
        if (response.status === 200) {
          commit('setOnboardingItems', response.data);
        } else {
          commit('setOnboardingItems', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setContentStatus', status || 0);
      });
  },
  getFAQThemes({ commit }) {
    return api.getFAQThemes()
      .then((response) => {
        commit('setContentStatus', response.status);
        if (response.status === 200) {
          commit('setFAQThemes', response.data);
        } else {
          commit('setFAQThemes', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setContentStatus', status || 0);
      });
  },
  getFAQTheme({ commit }, topicId) {
    return api.getFAQTheme({ topicId })
      .then((response) => {
        commit('setContentStatus', response.status);
        if (response.status === 200) {
          commit('setFAQTheme', response.data);
        } else {
          commit('setFAQTheme', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setContentStatus', status || 0);
      });
  },
  getFAQThemeQuestions({ commit }, { topicId }) {
    return api.getFAQThemeQuestions({ topicId })
      .then((response) => {
        commit('setContentStatus', response.status);
        if (response.status === 200) {
          commit('setFAQQuestions', response.data);
        } else {
          commit('setFAQQuestions', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setContentStatus', status || 0);
      });
  },
  getRule({ commit }) {
    return api.getRule()
      .then((response) => {
        commit('setContentStatus', response.status);
        if (response.status === 200) {
          commit('setRule', response.data);
        } else {
          commit('setRule', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setContentStatus', status || 0);
      });
  },
  getAmbassadors({ commit }) {
    return api.getAmbassadors()
      .then((response) => {
        commit('setContentStatus', response.status);
        if (response.status === 200) {
          commit('setAmbassadors', response.data);
        } else {
          commit('setAmbassadors', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setContentStatus', status || 0);
      });
  },
  getFAQThemeAnswers({ commit }, { faqTopicId, faqQuestionId }) {
    return api.getFAQThemeAnswers({ faqTopicId, faqQuestionId })
      .then((response) => {
        commit('setContentStatus', response.status);
        if (response.status === 200) {
          commit('setFAQAnswers', response.data);
        } else {
          commit('setFAQAnswers', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setContentStatus', status || 0);
      });
  },
  getBanners({ commit }) {
    return api.getBanners()
      .then((response) => {
        commit('setContentStatus', response.status);
        if (response.status === 200) {
          commit('setBanners', response.data);
        } else {
          commit('setBanners', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setContentStatus', status || 0);
      });
  },
  getIntroSections({ commit }) {
    return api.getIntroSections()
      .then((response) => {
        commit('setContentStatus', response.status);
        if (response.status === 200) {
          commit('setIntroSections', response.data);
        } else {
          commit('setIntroSections', null);
        }
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        commit('setContentStatus', status || 0);
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
