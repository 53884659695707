<template>
  <div class="calendar">
    <div v-if="!isMobileApp" class="divider"></div>
    <div v-if="!isMobileApp" class="calendar__menu">
      <Menu />
      <div class="divider"></div>
    </div>
    <div class="calendar__top">
      <div class="calendar__text">
        <h2 v-if="title" class="calendar__title">{{ title }}</h2>
        <div v-if="subtitle" @click="onMarket" class="calendar__subtitle-link">
          <p class="calendar__subtitle" v-html="subtitle"/>
          <Icon name="arrow-right" size="xxs" class="calendar__icon" />
        </div>
        <p v-if="updateDate" class="calendar__subtitle-update">Обновлено: {{ updateDate }}</p>
      </div>
      <div class="calendar__image">
        <img :src="eventsRafiki" alt="">
      </div>
    </div>
    <div class="divider"></div>
    <div class="calendar__wrapper">
      <div v-if="items" class="calendar__items">
        <div class="calendar__item"
          v-for="card in items" :key="card.id"
          :class="{'calendar__item--invert': card.invertColors, 'calendar__item--past': card.isPastMonth}"
        >
          <div
            class="calendar__item-wrapper" :style="`background-image: url('${!card.isPastMonth ? card.calendarMonthImages.active || '' : card.calendarMonthImages.past || ''}');`"
            >
            <div class="calendar__item-main">
              <p class="calendar__item-title">{{getMonthName(card.month)}} {{ card.year }}</p>
              <div class="calendar__item-events" v-for="event in getEvents(card.events)" :key="event.id">
                <div class="calendar__item-event" :class="{'calendar__item-event--past': event.isPast}">
                  <div class="calendar__item-event__left">
                    <div v-if="event.day" class="calendar__item-event__day">{{event.day}}</div>
                    <div v-if="event.discipline" class="calendar__item-event__day">
                      <Icon :name="toLowerCaseString(event.discipline)" size="m" />
                    </div>
                  </div>
                  <div class="calendar__item-event__main">
                    <div v-if="event.title" class="calendar__item-event__title">{{event.title}}</div>
                    <div v-if="event.subTitle" class="calendar__item-event__subtitle">{{event.subTitle}}</div>
                    <!-- <div v-if="event.logos.length > 1" class="calendar__item-event__logos">
                      <div class="calendar__item-event__logo" v-for="(logo, index) in event.logos" :key="index">
                        <img :src="logo" alt="" />
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="calendar__item__footer">
              <Button
                v-if="card.promotionsQuantity > 0"
                variant="secondary"
                size="md"
                mobile
                wide
                :text="`Смотреть все · ${card.promotionsQuantity}`"
                iconLeft="shopping-bag"
                @click="onMarketFilter(card)"
              ></Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Menu from '@/components/Menu/Menu.vue';

export default {
  name: 'Calendar',
  components: {
    Menu,
  },
  data() {
    return {
      items: [],
      title: '',
      subtitle: '',
      updateDate: '',
    };
  },
  computed: {
    ...mapGetters('settings', ['resources']),
    ...mapGetters('user', ['isMobileApp', 'profile']),
    ...mapGetters('calendar', ['calendar', 'responseStatus']),
    eventsRafiki() {
      return this.resources ? this.resources.eventsRafiki || '' : '';
    },
    navigationOffset() {
      return {
        default: '0', md: '72', lg: '72', xl: '84',
      };
    },
    paginationOffset() {
      return {
        default: '28', md: '44', lg: '44', xl: '60',
      };
    },
  },
  methods: {
    ...mapActions('user', ['getProfile']),
    ...mapActions('wallet', ['getBalance']),
    ...mapActions('calendar', ['getCalendar']),
    getEvents(events) {
      const pastEventsLimit = 4;
      const upcomingEventsLimit = 4;
      const pastEvents = events.filter((event) => event.isPast);
      const upcomingEvents = events.filter((event) => !event.isPast);
      let eventsToReturn = [];

      if (upcomingEvents.length >= upcomingEventsLimit) {
        eventsToReturn = upcomingEvents.slice(0, upcomingEventsLimit);
      } else if (upcomingEvents.length > 0) {
        const pastEventsNeeded = upcomingEventsLimit - upcomingEvents.length;
        eventsToReturn = pastEvents.slice(-pastEventsNeeded).concat(upcomingEvents);
      } else {
        eventsToReturn = pastEvents.slice(-pastEventsLimit);
      }
      return eventsToReturn;
    },
    onMarket() {
      const idCategory = '7d31ed61-5118-44b2-b6e9-8883e49c5bb2/cccff464-43d4-4f03-8141-b3dabed50fa9?category=cccff464-43d4-4f03-8141-b3dabed50fa9';
      const mobileTitle = '&title=Маркет';
      this.$router.push({ path: `/market/${idCategory}${this.isMobileApp ? mobileTitle : ''}` }).catch(() => {});
    },
    onMarketFilter(item) {
      const idCategory = '7d31ed61-5118-44b2-b6e9-8883e49c5bb2/cccff464-43d4-4f03-8141-b3dabed50fa9?category=cccff464-43d4-4f03-8141-b3dabed50fa9';
      const { month } = item;
      const mobileTitle = '&title=Маркет';
      this.$router.push({ path: `/market/${idCategory}&dateEvent=${month}${this.isMobileApp ? mobileTitle : ''}` }).catch(() => {});
    },
    toLowerCaseString(inputString) {
      if (typeof inputString === 'string') {
        return inputString.toLowerCase();
      }
      return inputString;
    },
    getMonthName(monthNumber) {
      const months = [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
      ];
      if (typeof monthNumber === 'number' && monthNumber >= 1 && monthNumber <= 12) {
        return months[monthNumber - 1];
      }
      return 'Неверное значение месяца';
    },
    getCalendarBlocks() {
      this.getCalendar()
        .then(() => {
          if (this.responseStatus === 200 && this.calendar) {
            this.items = this.calendar ? this.calendar.calendarMonths : [];
            this.title = this.calendar ? this.calendar.title : '';
            this.subtitle = this.calendar ? this.calendar.subtitle : '';
            this.updateDate = this.calendar ? dayjs.utc(this.calendar.updatedOn).format('DD.MM.YYYY') : '';
          }
        });
    },
  },
  mounted() {
    this.getProfile()
      .then(() => {
        this.getCalendarBlocks();
      });
    this.getBalance();
  },
};
</script>

<style lang="scss" scoped>
  @import "./Calendar";
</style>
